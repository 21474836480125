import $ from 'jQuery';
global.$ = global.jQuery = $;
/*
 * jQuery Easing v1.4.1 - http://gsgd.co.uk/sandbox/jquery/easing/
 * Open source under the BSD License.
 * Copyright © 2008 George McGinley Smith
 * All rights reserved.
 * https://raw.github.com/gdsmith/jquery-easing/master/LICENSE
*/
var pow = Math.pow,
  sqrt = Math.sqrt,
  sin = Math.sin,
  cos = Math.cos,
  PI = Math.PI,
  c1 = 1.70158,
  c2 = c1 * 1.525,
  c3 = c1 + 1,
  c4 = ( 2 * PI ) / 3,
  c5 = ( 2 * PI ) / 4.5;

// x is the fraction of animation progress, in the range 0..1
function bounceOut(x) {
  var n1 = 7.5625,
    d1 = 2.75;
  if ( x < 1/d1 ) {
    return n1*x*x;
  } else if ( x < 2/d1 ) {
    return n1*(x-=(1.5/d1))*x + 0.75;
  } else if ( x < 2.5/d1 ) {
    return n1*(x-=(2.25/d1))*x + 0.9375;
  } else {
    return n1*(x-=(2.625/d1))*x + 0.984375;
  }
}
$.extend( $.easing,
  {
    def: 'easeOutQuad',
    swing: function (x) {
      return $.easing[$.easing.def](x);
    },
    easeInQuad: function (x) {
      return x * x;
    },
    easeOutQuad: function (x) {
      return 1 - ( 1 - x ) * ( 1 - x );
    },
    easeInOutQuad: function (x) {
      return x < 0.5 ?
        2 * x * x :
        1 - pow( -2 * x + 2, 2 ) / 2;
    },
    easeInCubic: function (x) {
      return x * x * x;
    },
    easeOutCubic: function (x) {
      return 1 - pow( 1 - x, 3 );
    },
    easeInOutCubic: function (x) {
      return x < 0.5 ?
        4 * x * x * x :
        1 - pow( -2 * x + 2, 3 ) / 2;
    },
    easeInQuart: function (x) {
      return x * x * x * x;
    },
    easeOutQuart: function (x) {
      return 1 - pow( 1 - x, 4 );
    },
    easeInOutQuart: function (x) {
      return x < 0.5 ?
        8 * x * x * x * x :
        1 - pow( -2 * x + 2, 4 ) / 2;
    },
    easeInQuint: function (x) {
      return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
      return 1 - pow( 1 - x, 5 );
    },
    easeInOutQuint: function (x) {
      return x < 0.5 ?
        16 * x * x * x * x * x :
        1 - pow( -2 * x + 2, 5 ) / 2;
    },
    easeInSine: function (x) {
      return 1 - cos( x * PI/2 );
    },
    easeOutSine: function (x) {
      return sin( x * PI/2 );
    },
    easeInOutSine: function (x) {
      return -( cos( PI * x ) - 1 ) / 2;
    },
    easeInExpo: function (x) {
      return x === 0 ? 0 : pow( 2, 10 * x - 10 );
    },
    easeOutExpo: function (x) {
      return x === 1 ? 1 : 1 - pow( 2, -10 * x );
    },
    easeInOutExpo: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        pow( 2, 20 * x - 10 ) / 2 :
        ( 2 - pow( 2, -20 * x + 10 ) ) / 2;
    },
    easeInCirc: function (x) {
      return 1 - sqrt( 1 - pow( x, 2 ) );
    },
    easeOutCirc: function (x) {
      return sqrt( 1 - pow( x - 1, 2 ) );
    },
    easeInOutCirc: function (x) {
      return x < 0.5 ?
        ( 1 - sqrt( 1 - pow( 2 * x, 2 ) ) ) / 2 :
        ( sqrt( 1 - pow( -2 * x + 2, 2 ) ) + 1 ) / 2;
    },
    easeInElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        -pow( 2, 10 * x - 10 ) * sin( ( x * 10 - 10.75 ) * c4 );
    },
    easeOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        pow( 2, -10 * x ) * sin( ( x * 10 - 0.75 ) * c4 ) + 1;
    },
    easeInOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        -( pow( 2, 20 * x - 10 ) * sin( ( 20 * x - 11.125 ) * c5 )) / 2 :
        pow( 2, -20 * x + 10 ) * sin( ( 20 * x - 11.125 ) * c5 ) / 2 + 1;
    },
    easeInBack: function (x) {
      return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function (x) {
      return 1 + c3 * pow( x - 1, 3 ) + c1 * pow( x - 1, 2 );
    },
    easeInOutBack: function (x) {
      return x < 0.5 ?
        ( pow( 2 * x, 2 ) * ( ( c2 + 1 ) * 2 * x - c2 ) ) / 2 :
        ( pow( 2 * x - 2, 2 ) *( ( c2 + 1 ) * ( x * 2 - 2 ) + c2 ) + 2 ) / 2;
    },
    easeInBounce: function (x) {
      return 1 - bounceOut( 1 - x );
    },
    easeOutBounce: bounceOut,
    easeInOutBounce: function (x) {
      return x < 0.5 ?
        ( 1 - bounceOut( 1 - 2 * x ) ) / 2 :
        ( 1 + bounceOut( 2 * x - 1 ) ) / 2;
    }
  });

var __sessionClear = window.location.href.split("?").pop().split("=")[1] === "true";
if(__sessionClear){
  window.sessionStorage.removeItem("ichiko_banner");
}

var _haveSession = false;
if(window.sessionStorage.getItem("ichiko_banner") !== null){
  _haveSession = true;
}

global._isSP = undefined;

if(window.outerWidth < 751){
  _isSP = true;
}else{
  _isSP = false;
}

var PreInit = function(){
  var commonPartsChecker = setInterval(function(){
    if($("header").length > 0 && $("footer").length){
      clearInterval(commonPartsChecker);
      Init();
    }
  }, 100);
};

var Init = function(){
  var $window = $(window);
  var $body = $("body").addClass("loaded");
  var $header = $("header");

  $body.stop().delay(0).animate({opacity: 1}, 500);
  $header.stop().delay(0).animate({opacity: 1}, 300);

  var NavColorChange = function(){
    if($window.scrollTop() > $header.outerHeight()){
      if(!$header.hasClass("header--scrolled")){
        $header.addClass("header--scrolled");
      }
    }else{
      if($header.hasClass("header--scrolled")){
        $header.removeClass("header--scrolled");
      }
    }
  };
  $window.on("scroll", NavColorChange);
  NavColorChange();

  var $nav = $("nav.nav");

  var _navContentdelayTime = 30;
  $nav.find(".wait").each(function(i){
    var _$this = $(this);
    _$this.attr("data-delay", i * _navContentdelayTime);
  });

  var $navButton = $(".header-nav--btn, .nav--close");
  var NavOpenClose = function(){
    if($body.hasClass("nav--open")){
      $body.removeClass("is-modal");
      $nav.stop().animate({opacity: 0}, 200, function(){
        $nav.css({opacity: ""});
        $nav.find('.nav-content').animate({ scrollTop: 0 }, 0);
        ResetAnimate($nav);
        $body.removeClass("nav--open");
      });
    }else{
      $body.addClass("nav--open");
      $body.addClass("is-modal");
      AnimationStart($nav);
    }
  };

  $(".top-shop").on("click", function(){
    if($body.hasClass("nav--open")){
      NavOpenClose();
    }
    GoTopShopContent();
    return false;
  });

  $navButton.on("click", NavOpenClose);

  // 下部のバナー
  if(_haveSession === false){
    var _bnr = new JSONReader("#banner .banner-content", "top_banner");
    _bnr.read("../mt_output/json/top-banner.json", function(){
      var _$parent = $(_bnr.container.parent());
      _$parent.delay(2000).queue(function(){_$parent.addClass("appear").dequeue();});

      $(".banner--close").on("click", function(){
        _$parent.removeClass("appear");
      });
    });

    window.sessionStorage.setItem('ichiko_banner', new Date().getTime().toString());
  }


  // ScrollAnimatiopn
  window.AnimationStart = function($container){
    // console.log("AnimationStart");
    var _$wait = $container.find(".wait");
    _$wait.each(function(){
      var __$this = $(this);
      var __delay = __$this.data("delay");
      var __timeout = setTimeout(function(){
        __$this
          .removeClass("wait")
          .addClass("animation");
        clearTimeout(__timeout);
      }, __delay)
    });
  };

  window.ResetAnimate = function($container){
    // console.log("ResetAnimate");
    var _$animate = $container.find(".animation");
    var _$animating = $container.find(".animating");
    _$animate.each(function(){
      var __$this = $(this);
      __$this
        .addClass("wait")
        .removeClass("animation");
    });
    _$animating.each(function(){
      var __$this = $(this);
      __$this
        .addClass("waiting")
        .removeClass("animating");
    });

  };

  var setAnimation = function(){
    // console.log("setAnimation:");
    // console.log("scrollTop: " + $(scrollTarget).scrollTop());
    var $section = $(".waiting");
    var sectionLen = $section.length;
    var timing, _height, mainHeight, mainTop;
    _height = $window.height();
    mainTop = 0;
    timing = _height - (_height / 2);

    for(var i = 0; i < sectionLen; i++){
      var $wait = $($section[i]);
      var offsetTop;

      offsetTop = $wait.offset().top;
      var _delay = $wait.data("delay");
      if(_delay === void 0){
        _delay = 0;
      }
      $wait.delay(_delay).queue(function(){
        var __$this = $(this);
        __$this.removeClass("waiting").addClass('animating');
        __$this.dequeue();
      });
    }
  };

  var AnimationScroller = function(){
    setAnimation();

    if($body.css("opacity") !== "1"){
      $body.stop().animate({opacity: 1}, 100);
    }
  };

  $window.on("scroll", AnimationScroller);
  AnimationScroller();

  $(".pagetop").on("click", function(){
    $("html, body").stop().animate({scrollTop: 0}, 1000, "easeInOutCirc");
  });

  var Resize = function(){
    if($window.width() < 751){
      _isSP = true;
    }else{
      _isSP = false;
    }
  };
  $window.on("resize", Resize);
  Resize();


  /*
   * 記事コンテンツ
   * .mt-be-columns 処理
   */
  var $columns = $(".mt-be-columns");
  if($columns.length > 0){
    $columns.each(function(){
      var _$this = $(this);
      var _$column = _$this.find(".mt-be-column");
      if(_$column.length === 3){
        _$this.addClass("mt-be-columns3");
      }else if(_$column.length === 4){
        _$this.addClass("mt-be-columns4");
      }
    });
  }

  /*
   * 記事コンテンツ
   * 埋め込み動画/MAP iframe 処理
   */
  $(".article iframe").each(function(){
    var _$this = $(this);
    _$this.wrap("<div class='embed'></div>");
  });

  /*
   * 記事コンテンツ
   * aタグ href が画像パスの場合モーダルが開くようにする
   */
  var $articleA = $(".article a");
  if($articleA.length > 0){
    var $modal = $("#article_modal");
    var $modalClose = $("#article_modal_close");
    var $modalContent = $("#article_modal_content");
    var $modalOverlay = $("#article_modal_overlay");
    var _modalImage;

    var SetModalImage = function(){
      console.log("SetModalImage");
      $modalContent.append(_modalImage);
      $modal.css({display: "block"}).stop().animate({opacity: 1}, 300);
      $body.addClass("is-modal");

      _modalImage.removeEventListener("load", SetModalImage);
    };

    var ModalOpen = function(event){
      console.log("ModalOpen");
      var _src = $(event.currentTarget).find("img")[0].src;

      _modalImage = new Image();
      _modalImage.addEventListener("load", SetModalImage);
      _modalImage.src = _src;

      return false;
    };
    var ModalClose = function(event){
      $modalContent.empty();
      if($body.hasClass("is-modal")){
        $modal.stop().animate({opacity: 0}, 300, function(){
          $modal.css({display: "", opacity: ""});
          $body.removeClass("is-modal");
        });
      }else{
      }
    };

    $modalClose.on("click", ModalClose);
    $modalOverlay.on("click", ModalClose);

    $articleA.each(function(){
      var _$this = $(this);
      var _href = _$this.attr("href");
      if(_href.match(/.+(jpg|jpeg|gif|png)/) !== null){
        _$this.on("click", ModalOpen);
      }
    });
  }

  /*
   * 地図モーダル
   */
  var $map = $(".map");
  var $shopBtn = $(".shop--btn a");
  if($map.length > 0 && $shopBtn.length > 0){
    var $mapCloseIcon = $(".map--close");
    var OpenMapContent = function(){
      if($body.hasClass("is-modal")){
        CloseMapContent();
      }else{
        $map.addClass("map--open").stop().animate({opacity: 1}, 300);
        $body.addClass("is-modal");
      }
      return false;
    };
    var CloseMapContent = function(){
      $map.stop().animate({opacity: 0}, 300, function(){
        $map.removeClass("map--open").css({opacity: ""});
        $body.removeClass("is-modal");
      });
    };
    $shopBtn.on("click", OpenMapContent);
    $mapCloseIcon.on("click", CloseMapContent);

    var $mapContents = $(".map-content-area");
    var $mapTabs = $(".map--tab");
    var ChangeMap = function(event){
      var _$this = $(this);
      var _type = _$this.data("type");

      $mapContents.each(function(i){
        var _$content = $(this);
        var _contentType = _$content.data("type");

        if(_contentType === _type){
          _$content.addClass("map--current");
        }else{
          _$content.removeClass("map--current");
        }
      });

      $mapTabs.each(function(i){
        var _$tab = $(this);
        var _tabType = _$tab.data("type");

        if(_tabType === _type){
          _$tab.addClass("map--current");
        }else{
          _$tab.removeClass("map--current");
        }
      });
    };
    $mapTabs.on("click", ChangeMap);
  }

  /**
   *
   * @param event
   * @returns {boolean}
   * @constructor
   */
  var Fadeout = function(event){
    var _$target = $(this);
    var _href = _$target.attr("href");
    if(
      _href.indexOf(".gif") < 0 &&
      _href.indexOf(".png") < 0 &&
      _href.indexOf(".jpg") < 0 &&
      _href.indexOf(".jpeg") < 0
    ){
      if(_href !== "" && _href.indexOf("#") < 0){
        if(_$target.attr("target") !== "_blank"){
          $body.animate({opacity: 0}, 500, function(){
            $body.removeClass("nav--open").removeClass("is-modal")
            window.location.href = _href;
            $body.delay(1000).animate({opacity: 1});
          });
          return false;
        }
      }
    }
  };
  $("a").not('.no-fade').on("click", Fadeout);

};

window.addEventListener("load", PreInit);

global.GoTopShopContent = function(){
  $("html, body").stop().animate({opacity: 1, scrollTop: $("#shop").offset().top}, 500, "easeInOutCirc");
  return false;
};

class CommonPartsInclude {
  constructor(file, targetNode, place){
    this.file = file;
    this.targetNode = targetNode;
    this.place = place;
    this.xhr = new XMLHttpRequest();
  }

  read(){
    var _this = this;

    var _xhr = _this.xhr;
    _xhr.open("GET", _this.file);

    _xhr.onload = function(){
      if(_this.place === "before"){
        $(_this.targetNode).before($(_xhr.response));
      }else if(_this.place === "after"){
        $(_this.targetNode).after($(_xhr.response));
      }else{
        $(_this.targetNode).append($(_xhr.response));
      }
    };

    _xhr.onerror = function(){};

    _xhr.send();
  }
}

class JSONReader {
  constructor(container, type){
    this.container = $(container);
    this.type = type;
    this.url = "";
    this.parse = {};
    this.count = 0;
    this.max = 0;
    this.moreAddCount = 6;
    this.xhr = new XMLHttpRequest();
  }

  read(url, max, callback){
    console.log("read");
    var _this = this;
    var _callback;
    _this.url = url;

    if(typeof(max) === "function"){
      _callback = max;
      _this.max = 0;
    }else{
      _callback = callback;
      _this.max = 0;
    }

    var _xhr = this.xhr;
    _xhr.open("GET", this.url);

    _xhr.onload = function(){
      _this.parse = JSON.parse(_xhr.response);

      if(_this.type === "mv"){
        _this.outputMV(_callback);
      }else if(_this.type === "mv_info"){
        _this.outputTopInfo(_callback);
      }else if(_this.type === "top_banner"){
        _this.outputTopBanner(_callback);
      }else if(_this.type === "readings"){
        _this.outputReadings(max, _callback);
      }else if(_this.type === "news"){
        _this.outputNews(max, _callback);
      }else if(_this.type === "incense"){
        _this.outputIncensePromotion(max, _callback);
      }
    };
    _xhr.onerror = function(){};

    _xhr.send();
  }

  outputMV(callback){
    console.log("outputMV");

    var _this = this;
    var _count = 0;

    var _articles = this.parse.promotions;

    for(var i = 0; i < _articles.length; i++){
      if(_count >= 6){
        //return;
      }

      var _id, _link, _blank, _imagePC, _imageSP, _category, _subcategory, _txt, _txtSmall;
      var _articleObj = _articles[i];
      _id = _articleObj["id"];
      _link = _articleObj["url"];
      _blank = _articleObj["_blank"];
      _imagePC = _articleObj["image_pc"];
      _imageSP = _articleObj["image_sp"];
      _category = _articleObj["category"];
      _subcategory = _articleObj["subcategory"];
      _txt = _articleObj["text"];
      _txtSmall = _articleObj["text_ja"];

      var _$box = $("<div class='mv-box' data-mv-id='" + _id + "'></div>");
      var _$boxA = $("<a href='" + _link + "' class='no-fade'></a>");
      if(_blank){
        _$boxA.attr("target", "blank");
      }
      var _$visual = $("<div class='mv--thumb'><img src='" + _imagePC + "' class='display-pc'><img src='" + _imageSP + "' class='display-sp'></div>");
      var _$info = $("<div class='mv-info'></div>");
      var _$infoInner = $("<div class='mv-info-inner'></div>");
      _category += (_subcategory !== "") ? " - " +  _subcategory: "";
      var _$category = $("<p class='mv--category'>" + _category + "</p>");
      var _$txt = $("<p class='mv--txt'>" + _txt + "</p>");
      var _$txtSmall = $("<p class='mv--txt-s'>" + _txtSmall + "</p>");

      _$infoInner.append(_$category);
      _$infoInner.append(_$txt);
      _$infoInner.append(_$txtSmall);
      _$info.append(_$infoInner);
      _$boxA.append(_$visual);
      _$boxA.append(_$info);
      _$box.append(_$boxA);

      if(_count === 0){
        _$box.addClass("mv-box--open _scale1");
      }

      _this.container.append(_$box);

      _count++;
    }

    if(callback){
      callback();
    }

  }

  outputTopInfo(callback){
    console.log("outputTopInfo");

    var _this = this;
    var _count = 0;

    var _articles = this.parse.information;
    for(var i = 0; i < _articles.length; i++){
      if(_count >= 1){
        return;
      }
      var _articleObj = _articles[i];
      var _id, _link, _blank, _txt;
      _id = _articleObj["id"];
      _link = _articleObj["url"];
      _blank = _articleObj["_blank"] === true ? "target='_blank'" : "";
      _txt = _articleObj["text"];

      var _$p = $("<p class='note--txt' data-id='" + _id + "'></p>");
      var _$a = $("<a href='" + _link + " " + _blank + "'></a>");
      var _$span = $("<span>" + _txt + "</span>");

      _$a.append(_$span);
      _$a.append('<svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="#525654"><g id="a"><polygon points="1.04 14.62 0 13.54 6.47 7.31 0 1.08 1.04 0 8.63 7.31 1.04 14.62"/></g></svg>');

      _$p.append(_$a);
      _this.container.append(_$p);
    }

    //<svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="#525654"><g id="a"><polygon points="1.04 14.62 0 13.54 6.47 7.31 0 1.08 1.04 0 8.63 7.31 1.04 14.62"/></g></svg>
  }

  outputTopBanner(callback){
    console.log("outputTopBanner");

    var _this = this;
    var _count = 0;

    var _articles = this.parse.banner;

    if(_articles.length === 0){
      _this.container.remove();
      return;
    }

    for(var i = 0; i < _articles.length; i++){
      if(_count >= 1){
        return;
      }
      var _articleObj = _articles[i];
      var _id, _link, _blank, _imagePC, _txt;
      _id = _articleObj["id"];
      _link = _articleObj["url"];
      _blank = _articleObj["_blank"] === true ? "target='_blank'" : "";
      _imagePC = _articleObj["image_pc"];
      _txt = _articleObj["text"];

      var _$a = $("<a href='" + _link + " " + _blank + "' data-id='" + _id + "'></a>");
      var _$p = $("<p class='banner--txt'>" + _txt + "</p>");
      var _$close = $("<div class='banner--close'><span></span><span></span></div>");

      if(_imagePC !== ""){
        var _$img = $("<div class='banner--img'><img src='" + _imagePC + "'></div>");
        _$a.append(_$img);
      }else{
        _this.container.parent().addClass("no-image");
      }

      _$a.append(_$p);
      _this.container.append(_$a);
      _this.container.append(_$close);
    }

    if(callback){
      callback();
    }

  }

  outputNews(max, callback){
    console.log("outputNews");
    var _this = this;
    _this.max += max;

    var _articles = this.parse.articles;
    for(var i = _this.count; i < _articles.length; i++){
      if(_this.count >= _this.max){
        return;
      }

      var _id, _date, _link, _thumb, _categoryClass, _categoryName, _txt;
      var _articleObj = _articles[i];
      _id = _articleObj["id"];
      _date = _articleObj["date"];
      _link = _articleObj["url"];
      _thumb = _articleObj["thumb_url"];
      _categoryClass = _articleObj["category"]["class"];
      _categoryName = _articleObj["category"]["name"];
      _txt = _articleObj["text"];

      var _$box = $("<div class='news-box' data-article-id='" + _id + "' data-category='" + _categoryClass + "'></div>");
      var _$a = $("<a href='" + _link + "'>");
      var _$thumb = $("<div class='news--thumb'><img src='" + _thumb + "' alt='' width='320' height='180'></div>");
      var _$info = $("<div class='news-explain'><p class='news--txt'>" + _txt + "</p><p class='news--date'><span>" + _categoryName.toUpperCase() + "</span><span>" + _date.toUpperCase() + "</span></p></div>");

      _$a.append(_$thumb);
      _$a.append(_$info);
      _$a.append('<div class="news--arrow"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="#525654"><g id="a"><polygon points="1.04 14.62 0 13.54 6.47 7.31 0 1.08 1.04 0 8.63 7.31 1.04 14.62"/></g></svg></div>');
      _$box.append(_$a);
      _this.container.append(_$box);

      _this.count++;
    }
    if(callback){
      callback();
    }
  }

  outputReadings(max, callback){
    console.log("outputReadings");
    var _this = this;
    _this.max = max;

    var _articles = this.parse.articles;
    for(var i = _this.count; i < _articles.length; i++){
      if(_this.count >= _this.max){
        return;
      }

      var _id, _type, _link, _thumb, _categoryClass, _categoryName, _txt, _date, _tags;
      var _articleObj = _articles[i];
      _id = _articleObj["id"];
      _categoryName = _articleObj["category"]["name"];
      _categoryClass = _articleObj["category"]["class"];
      _date = _articleObj["date"];
      _link = _articleObj["url"];
      _txt = _articleObj["text"];
      _thumb = _articleObj["thumb_url"];
      _tags = _articleObj["tags"];

      var _$box = $("<div class='readings-box' data-article-id='" + _id + "'></div>");
      var _$a = $("<a href='" + _link + "'></a>");
      var _$thumb = $("<div class='readings--thumb'><img src='" + _thumb + "' alt=''></div>");
      var _$p = $("<p class='readings--txt'>" + _txt + "</p>");
      var _$date = $("<p class='readings--date'><span>" + _categoryClass.toUpperCase() + "</span><span>" + _date + "</span></p>");
      var _$tags = $("<p class='readings--tag'>#" + _tags.replace(",", "／#") + "</p>");

      _$a.append(_$thumb);
      _$a.append(_$p);
      _$a.append(_$date);
      _$a.append(_$tags);
      _$box.append(_$a);
      _this.container.append(_$box);

      _this.count++;
    }

    if(callback){
      callback();
    }
  }

  outputIncensePromotion(max, callback){
    console.log("outputIncensePromotion");
    var _this = this;
    var _count = 0;

    var _articles = this.parse.promotions;
    for(var i = 0; i < _articles.length; i++){
      if(_count >= max){
        return;
      }

      var _id, _link, _blank, _thumb, _txt;
      var _articleObj = _articles[i];
      _id = _articleObj["id"];
      _link = _articleObj["url"];
      _thumb = _articleObj["thumb_url"];
      _txt = _articleObj["text"];

      _blank = _articleObj["blank"] === "true" ? " target='_blank'" : "";

      var _$box = $("<div class='products-category-carousel-box' data-promotion-id='" + _id + "'></div>");
      var _$a = $("<a href='" + _link + "'" + _blank + ">");
      var _$thumb = $("<div class='products-category-carousel--img'><img src='" + _thumb + "' alt='" + _txt + "'></div>");
      var _$txt = $("<p class='products-category-carousel--txt'>" + _txt + "</p>");

      if(_count === 0){
        _$box.addClass("products-category-carousel-box--open");
      }

      _$a.append(_$thumb);
      _$a.append(_$txt);
      _$box.append(_$a);
      _this.container.append(_$box);

      _count++;
    }
    if(callback){
      callback();
    }
  }
}

global.CommonPartsInclude = CommonPartsInclude;
global.JSONReader = JSONReader;

var __header = new CommonPartsInclude("/assets/include/header.html","main","before");
__header.read();
var __footer = new CommonPartsInclude("/assets/include/footer.html","main","after");
__footer.read();